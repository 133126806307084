import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import ColorLegend from '../../ColorLegend/ColorLegend';
import './TrMap.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = () => {
    const mapContainerRef = useRef(null); // Ref for the map container

    const items = [
        { color: '#4fff3b', label: 'Good' },
        { color: '#ffff00', label: 'Good-to-Moderate' },
        { color: '#ffbc1a', label: 'Moderate-to-Poor' },
        { color: '#ff361a', label: 'Poor-to-Very poor' },
    ];

    useEffect(() => {
        // Ensure the map container is available
        if (!mapContainerRef.current) return;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/carbin/clojv7hqb006f01qognk06l0n',
            projection: 'mercator',
            zoom: 7,
            center: [139.839478, 35.652832]
        });

        map.on('load', () => {
            map.addSource('tr-road', {
                type: 'vector',
                url: 'mapbox://carbin.tr-road-vector',
            });
            map.addLayer({ 
                'id': 'tr-road',
                'type': 'line',
                'source': 'tr-road',
                'source-layer': 'tr-road',  
                'paint': {
                  'line-color': [
                    'step',
                    ['get', 'Iri'],   // Use 'Iri' values for coloring
                    '#4fff3b',        // Green for Iri < 1.5
                    1.5, '#ffff00',   // Yellow for 1.5 <= Iri < 3.47
                    3.47, '#ffbc1a',  // Orange for 3.47 <= Iri < 5.05
                    5.05, '#ff361a'   // Red for Iri >= 5.05
                  ],
                  'line-width': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    10, 1,  // Line width of 2 at zoom level 10
                    16, 6   // Line width of 6 at zoom level 16
                  ]
                }
              });
        });

        map.addControl(new mapboxgl.NavigationControl(), 'top-right');

        return () => map.remove();
    }, []);

    return (
        <div >
            <div ref={mapContainerRef} className="map-container" />
            <ColorLegend items={items} />
        </div>
    );
};

export default Map;
