import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import ColorLegend from '../ColorLegend/ColorLegend';
import './Map.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = () => {
    const mapContainerRef = useRef(null); // Ref for the map container

    const items = [
        { color: '#4fff3b', label: 'Good' },
        { color: '#ffff00', label: 'Good-to-Moderate' },
        { color: '#ffbc1a', label: 'Moderate-to-Poor' },
        { color: '#ff361a', label: 'Poor-to-Very poor' },
    ];

    useEffect(() => {
        // Ensure the map container is available
        if (!mapContainerRef.current) return;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/carbin/clojv7hqb006f01qognk06l0n',
            projection: 'mercator',
            zoom: 10,
            center: [-71.057083, 42.361145]
        });

        map.on('load', () => {
            map.addSource('iri', {
                type: 'vector',
                url: 'mapbox://carbin.iri',
            });

            map.addLayer(
                {
                    'id': 'iri',
                    'type': 'circle',
                    'source': 'iri',
                    'source-layer': 'iri',
                    'paint': {
                        'circle-color': [
                            'step',
                            ['get', 'iri'],
                            '#4fff3b',        // Very Good: Green for iri < 1.5
                            1.5, '#ffff00',   // Good: Light Green for 1.5 <= iri < 3.47
                            3.47, '#ffbc1a',  // Poor: Orange for 3.47 <= iri < 5.05
                            5.05, '#ff361a',  // Very Poor: Red for iri >= 5.05
                        ],
                        'circle-radius': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            11, 1,
                            16, 4
                        ]
                    }
                },
                'road-label-simple'
            );

            map.addSource('tr-iri', {
                type: 'vector',
                url: 'mapbox://carbin.Iri-taisei-rotec',
            });

            map.addLayer(
                {
                    'id': 'tr-iri',
                    'type': 'circle',
                    'source': 'tr-iri',
                    'source-layer': 'taisei-rotec',
                    'paint': {
                        'circle-color': [
                            'step',
                            ['get', 'Iri'],
                            '#4fff3b',        // Very Good: Green for iri < 1.5
                            1.5, '#ffff00',   // Good: Light Green for 1.5 <= iri < 3.47
                            3.47, '#ffbc1a',  // Poor: Orange for 3.47 <= iri < 5.05
                            5.05, '#ff361a',  // Very Poor: Red for iri >= 5.05
                        ],
                        'circle-radius': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            11, 1,
                            16, 4
                        ]
                    }
                },
                'road-label-simple'
            );
        });

        map.addControl(new mapboxgl.NavigationControl(), 'top-right');

        return () => map.remove();
    }, []);

    return (
        <div >
            <div ref={mapContainerRef} className="map-container" />
            <ColorLegend items={items} />
        </div>
    );
};

export default Map;
