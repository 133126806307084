import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Map from "./components/Map/Map";
import TrMap from "./components/clients/tr/TrMap";
import logo from './assets/CarbinLogo.png';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <header className="app-header">
          <img src={logo} alt="Company Logo" className="logo" />
        </header>
        <Routes>
          <Route path="/" element={<Map />} />
          <Route path="/tr" element={<TrMap />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
